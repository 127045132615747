<template>
  <div>
    <v-dialog data-cy="dialog" v-model="showDialog" max-width="450">
      <v-card>
        <v-toolbar flat color="blueGreyLight" class="text">
          <v-toolbar-title>
            {{ toolbarTitle }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="mt-10">
          <div v-for="(item, index) in items" :key="index" class="my-5">
            <v-row>
              <v-col cols="3">
                <v-sheet class="text primary--text text-subtitle-1">{{ item.type }}:&nbsp; </v-sheet>
              </v-col>
              <v-col cols="7">
                <v-row class="mt-1">
                  <v-sheet
                    v-for="(permission, index) in item.permissions"
                    :key="index"
                    class="text"
                    >{{ permission }}&nbsp;</v-sheet
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
    }
  },

  props: {
    toolbarTitle: String,
    items: Array,
  },

  methods: {
    openDialog() {
      this.showDialog = true
    },
  },
}
</script>

<style scoped></style>
